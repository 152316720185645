
export function get_mutiple_ints (param_name) {
    const urlParams = new URLSearchParams(window.location.search)
    try{
        return urlParams.get(param_name)
            .split(',')
            .map(id=>{
                let ret = Number(id)
                if(isNaN(ret)) throw new Error()
                return ret
            })
    } catch {
        return undefined
    }
}

export function get_mutiple_strings(param_name) {
    const urlParams = new URLSearchParams(window.location.search)
    try{
        return urlParams.get(param_name)
            .split(',')
            .map(id=>{
                return String(id)
            })
    } catch {
        return undefined
    }
}

export function get_single_string(param_name) {
    const urlParams = new URLSearchParams(window.location.search)
    try{
        return String(urlParams.get(param_name))
    } catch {
        return undefined
    }
}