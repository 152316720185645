/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import 'bootstrap';
import 'select2/dist/css/select2.css';
import '../favicons/favicons';
import * as cookiesManagement from './cookiesManagement.js';
global.cookiesManagement = cookiesManagement;
import * as urlParams from './urlParams.js';
global.urlParams = urlParams;

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
// const autocomplete = require('autocomplete.js'); #FK: not used, and makes tableFilter to bug (Object(...) is not a function)
const select2 = require('select2/dist/js/select2.js');
var tableFilter = require('tablefilter');
global.tableFilter = tableFilter;
global.tableFilterDefaultConfig = {
    base_path :'/build/external/tablefilter/',
    auto_filter: { delay: 500 },        // Delay for automatic filtering (milliseconds)
    sticky_headers: true,					// Sticky headers with overrides
    alternate_rows: true,					// Enable alternating rows
    toolbar: {
        target_id: 'tableFilterExternalToolbar'
    },
    highlight_keywords: true,
    ignore_diacritics: true,
    locale: 'fr',
    clear_filter_text: 'Tous',
    state: {
        types: ['hash'],
        filters: true,
        page_number: true,
        page_length: true,
        sort: true,
        columns_visibility: false,
        filters_visibility: false
    },
}


// create global $ and jQuery variable
global.$ = $;
global.jQuery = $;

// when using the twig function "{{ render(controller('...')) }}", js scripts imported with "encore_entry_script_tag" are sometimes executed twice. Use this global list so that each script can check whether it has already been executed.
if(global.EXECUTED_SCRIPTS==undefined){
    global.EXECUTED_SCRIPTS = [];
}

// A helper function to check whether an element is visible on the page or not (this includes the parents visibility).
// see https://stackoverflow.com/questions/1343237/how-to-check-elements-visibility-via-javascript
global.isVisible = function(elem){
    return elem.offsetWidth > 0 || elem.offsetHeight > 0;
}

// see https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
global.copyTextToClipboard = function(text){
    var textArea = document.createElement("textarea");
    textArea.style.cssText = 'position:fixed; top:0; left:0; width:"2em"; height:"2em"; padding:0; border:"none"; outline:"none"; boxShadow:"none"; background:"transparent"';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text "'+text+'" was ' + msg);
    } catch (err) {
        console.log('Oops, unable to copy');
    }
    document.body.removeChild(textArea);
}
